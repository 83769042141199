'use client';
import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import {
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
  FaFacebook,
  FaGithub,
  FaLink,
  FaPencil,
  FaUser,
  FaBookmark,
  FaMapPin,
  FaIndianRupeeSign,
} from 'react-icons/fa6';
import {
  FaBuilding,
  FaMapMarkerAlt,
  FaGraduationCap,
  FaInfoCircle,
  FaCalendarAlt,
  FaBriefcase,
  FaUniversity,
  FaBook,
} from 'react-icons/fa';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { baseURL } from '@/utils/BaseURL';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const EventCard = ({ opportunity, orientation, adminMode = false }) => {
  // console.log("opportunity", opportunity);
  const router = useRouter();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { data: session, status } = useSession();
  const [bookmarkColor, setBookmarkColor] = useState(
    opportunity?.is_bookmarked ? 'text-purple-600' : 'text-slate-950'
  );

  const opportunityRef = opportunity?.alias
    ? opportunity?.alias
    : opportunity?.id;

  useEffect(() => {
    if (session && status === 'authenticated') {
      setIsLoggedIn(true);
    }
  }, [session, status]);

  const handleClick = async opportunityId => {
    if (!isLoggedIn) {
      // Redirect to login if not logged in

      router.push('/auth');
      return;
    }

    const token = session?.accessToken;

    try {
      setBookmarkColor(prevColor =>
        prevColor === 'text-purple-600' ? 'text-slate-950' : 'text-purple-600'
      );
      // Send a POST request to add bookmark
      const response = await fetch(
        `${baseURL}/opportunity/${opportunityId}/bookmark/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        if (response.status === 201)
          toast.success('Bookmark added successfully');
        else if (response.status === 200)
          toast.success('Bookmark removed successfully');
        // Handle success if needed
      } else {
        toast.error('Failed to add bookmark');
        // Handle failure if needed
        setBookmarkColor(prevColor =>
          prevColor === 'text-purple-600' ? 'text-slate-950' : 'text-purple-600'
        );
      }
    } catch (error) {
      toast.error('Error handling bookmark', error);
      setBookmarkColor(prevColor =>
        prevColor === 'text-purple-600' ? 'text-slate-950' : 'text-purple-600'
      );
    }
  };

  const [eventStatus, setEventStatus] = useState('');

  useEffect(() => {
    const currentDate = new Date().getTime();
    let startDate = new Date(opportunity?.start_date);
    startDate.setHours(0, 0, 0, 0);
    startDate = startDate.getTime();
    let endDate = new Date(opportunity?.end_date);
    endDate.setHours(23, 59, 59, 999);
    endDate = endDate.getTime();

    if (opportunity?.is_verified === false) setEventStatus('Unpublished');
    else if (currentDate < startDate) setEventStatus('Upcoming');
    else if (currentDate >= startDate && currentDate <= endDate)
      setEventStatus('Ongoing');
    else setEventStatus('Ended');
  }, [
    opportunity?.start_date,
    opportunity?.end_date,
    opportunity?.is_verified,
  ]);

  // making the whole card clickable
  const handleCardClick = e => {
    if (!e.target.closest('.bookmark-icon')) {
      // Add your logic here to navigate when the card is clicked
      router.push(eventLink);
    }
  };

  // handling the bookmark click different from the whole card
  const handleBookmarkClick = e => {
    e.preventDefault();
    handleClick(opportunityRef);
  };

  const registrationDeadline = new Date(
    `${opportunity?.registration_deadline}T23:59:59`
  );
  const currentDate = new Date();

  const eventLink = opportunity?.is_external_event
    ? opportunity?.external_link
    : `/events/${opportunityRef}`;

  const dashboardLink = `/events/${opportunityRef}/dashboard`;

  return (
    <>
      {orientation === 'horizontal' ? (
        <div
          onClick={handleCardClick}
          className="flex rounded-lg shadow-lg shadow-gray-200 scale-[0.95] w-[660px] overflow-hidden transition-transform hover:scale-[0.97] duration-300 relative cursor-pointer "
          style={{ cursor: 'pointer', aspectRatio: '2 / 1' }}
        >
          {/* Live status indication */}
          <div className="flex-shrink-0  relative w-[50%]">
            {eventStatus === 'Upcoming' && (
              <div className="absolute top-2 left-2 flex items-center gap-2 bg-yellow-500 pl-2 pr-2 rounded-full z-10">
                <div className="w-2 h-2 bg-white rounded-full"></div>
                <p className="text-white font-semibold">Upcoming</p>
              </div>
            )}

            {eventStatus === 'Ongoing' && (
              <div className="absolute top-2 left-2 flex items-center gap-2 bg-green-500 pl-2 pr-2 rounded-full z-10">
                <div className="w-2 h-2 bg-white rounded-full"></div>
                <p className="text-white font-semibold">Ongoing</p>
              </div>
            )}

            {eventStatus === 'Ended' && (
              <div className="absolute top-2 left-2 flex items-center gap-2 bg-gray-500 pl-2 pr-2 rounded-full z-10">
                <div className="w-2 h-2 bg-white rounded-full"></div>
                <p className="text-white font-semibold">Ended</p>
              </div>
            )}
            {eventStatus === 'Unpublished' && (
              <div className="absolute top-2 left-2 flex items-center gap-2 bg-gray-500 pl-2 pr-2 rounded-full z-10">
                <div className="w-2 h-2 bg-white rounded-full"></div>
                <p className="text-white font-semibold">Unpublished</p>
              </div>
            )}

            <Image
              alt="Home"
              src={
                opportunity?.opportunity_main_picture
                  ? opportunity?.opportunity_main_picture
                  : 'https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
              }
              fill={true}
              className="object-cover"
            />
          </div>

          <div className="flex-1 p-3">
            <div className="flex items-center justify-between">
              <div className="mt-1 sm:mt-0">
                <p className="font-semibold text-purple-500 text-base">
                  {opportunity?.opportunity_type}
                </p>
              </div>
              <div
                className="sm:inline-flex sm:shrink-0 sm:items-center sm:justify-end bookmark-icon"
                onClick={handleBookmarkClick}
                style={{ cursor: 'pointer' }}
              >
                {/* Standard bookmark icon */}
                <FaBookmark size={25} className={bookmarkColor} />
              </div>
            </div>
            <div className="mt-1 flex items-center justify-between text-xs h-16">
              <div className="flex">
                <div className="mt-0 sm:mt-0">
                  <h4 className="text-2xl font-bold line-clamp-2">
                    {opportunity?.name}
                  </h4>
                </div>
              </div>
            </div>

            <div className="my-2">
              <p className="text-sm text-gray-700 mt-1 line-clamp-3">
                {opportunity?.description}
              </p>
              <Link href={eventLink} className="">
                <span className="text-slate-950 cursor-pointer text-sm font-bold">
                  Read more
                </span>
              </Link>
            </div>
            <div className="flex items-center justify-between text-xs text-gray-700">
              <div className="flex items-center gap-10">
                <div className="flex items-center">
                  <FaCalendarAlt className="mr-1 h-4 w-4 text-slate-950" />
                  <p className="mr-4 text-sm">
                    {new Date(opportunity?.start_date).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex items-center mr-auto">
                  <FaMapPin className="mr-1 h-4 w-4 text-slate-950" />
                  <p className="text-sm">
                    {opportunity?.mode === 'In Person'
                      ? opportunity?.location
                      : 'Online'}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex my-2">
              <FaIndianRupeeSign className="mr-1 h-4 w-4 text-slate-950" />
              {opportunity?.is_paid ? (
                <p className="text-sm">{opportunity?.fees}</p>
              ) : (
                <p className="text-sm">Free</p>
              )}
            </div>
            <div className="mt-5 flex items-center justify-center">
              {adminMode ? (
                <Link href={dashboardLink} className="">
                  <Button className="w-full text-xl bg-[#6956e3] text-white">
                    Go to Dashboard
                  </Button>
                </Link>
              ) : (
                <div style={{ height: 'auto', width: '100%' }}></div>
              )}
              {opportunity?.is_verified && !adminMode ? (
                <Link href={eventLink} className="">
                  <Button
                    style={{
                      backgroundColor:
                        registrationDeadline < currentDate
                          ? '#404040'
                          : '#6956e3',
                      color: '#ffffff',
                      cursor:
                        registrationDeadline < currentDate
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                    className="w-full text-xl"
                    disabled={registrationDeadline < currentDate}
                  >
                    {registrationDeadline < currentDate
                      ? 'Registration Closed'
                      : opportunity?.is_registered
                        ? 'Registered!'
                        : 'Register Now!'}
                  </Button>
                </Link>
              ) : (
                <div style={{ height: 'auto', width: '100%' }}></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={handleCardClick}
          className="block rounded-lg shadow-lg shadow-gray-200    mx-3 scale-[1.0] overflow-hidden transition-transform hover:scale-[1.02]  duration-300 relative  cursor-pointer  my-[-40px]"
          // style={{ width:'full', height:'full'  }}
        >
          {/* Live status indication */}
          {eventStatus === 'Upcoming' && (
            <div className="absolute top-2 right-2 flex items-center gap-2 bg-yellow-500 pl-2 pr-2 rounded-full">
              <div className="w-2 h-2 bg-white rounded-full"></div>
              <p className="text-white font-semibold">Upcoming</p>
            </div>
          )}

          {eventStatus === 'Ongoing' && (
            <div className="absolute top-2 right-2 flex items-center gap-2 bg-green-500 pl-2 pr-2 rounded-full">
              <div className="w-2 h-2 bg-white rounded-full"></div>
              <p className="text-white font-semibold">Ongoing</p>
            </div>
          )}

          {eventStatus === 'Ended' && (
            <div className="absolute top-2 right-2 flex items-center gap-2 bg-gray-500 pl-2 pr-2 rounded-full">
              <div className="w-2 h-2 bg-white rounded-full"></div>
              <p className="text-white font-semibold">Ended</p>
            </div>
          )}
          {eventStatus === 'Unpublished' && (
            <div className="absolute top-2 right-2 flex items-center gap-2 bg-gray-500 pl-2 pr-2 rounded-full">
              <div className="w-2 h-2 bg-white rounded-full"></div>
              <p className="text-white font-semibold">Unpublished</p>
            </div>
          )}
          <div className=" flex  rounded-md object-cover ">
            <Image
              alt="Home"
              src={
                opportunity?.opportunity_main_picture
                  ? opportunity?.opportunity_main_picture
                  : 'https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
              }
              height={50}
              width={300}
              // objectFit="cover"
              className="h-full w-full"
            />
          </div>
          <div className="p-3">
            <div className="flex items-center justify-between">
              <div className="mt-1 sm:mt-0">
                <p className="font-semibold text-purple-500 text-base">
                  {opportunity?.opportunity_type}
                </p>
              </div>
              <div
                className="sm:inline-flex sm:shrink-0 sm:items-center sm:justify-end bookmark-icon"
                onClick={handleBookmarkClick}
                style={{ cursor: 'pointer' }}
              >
                {/* Standard bookmark icon */}
                <FaBookmark size={20} className={bookmarkColor} />
              </div>
            </div>
            <div className="mt-1 flex items-center justify-between text-xs h-16">
              <div className="flex">
                <div className="mt-0 sm:mt-0">
                  <h4 className=" text-2xl font-bold line-clamp-2">
                    {opportunity?.name}
                  </h4>
                </div>
              </div>
            </div>

            <div className="my-2">
              <p className="text-sm text-gray-700 mt-1 line-clamp-2">
                {opportunity?.description}
              </p>
              <Link href={eventLink} className="">
                <span className="text-slate-950 cursor-pointer text-sm font-bold">
                  Read more
                </span>
              </Link>
            </div>
            <div className=" items-center justify-between text-xs text-gray-700">
              <div className="flex items-center">
                <div className="flex items-center">
                  <FaCalendarAlt className="mr-1 h-4 w-4 text-slate-950" />
                  <p className="mr-4 text-sm">
                    {new Date(opportunity?.start_date).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex items-center mr-auto">
                  <FaMapPin className="mr-1 h-4 w-4 text-slate-950" />
                  <p className="text-sm ">
                    {opportunity?.mode === 'In Person'
                      ? opportunity?.location
                      : 'Online'}
                  </p>
                </div>
              </div>

              <div className="flex my-2">
                <FaIndianRupeeSign className="mr-1 h-4 w-4 text-slate-950" />
                {opportunity?.is_paid ? (
                  <p className="text-sm">{opportunity?.fees}</p>
                ) : (
                  <p className="text-sm">Free</p>
                )}
              </div>
            </div>
            <div className="">
              {opportunity?.is_verified ? (
                <Link href={eventLink} className="">
                  <Button
                    style={{
                      backgroundColor:
                        registrationDeadline < currentDate
                          ? '#404040'
                          : '#6956e3',
                      color: '#ffffff',
                      cursor:
                        registrationDeadline < currentDate
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                    className="w-full text-xl"
                    disabled={registrationDeadline < currentDate}
                  >
                    {registrationDeadline < currentDate
                      ? 'Registration Closed'
                      : opportunity?.is_registered
                        ? 'Registered!'
                        : 'Register Now!'}
                  </Button>
                </Link>
              ) : (
                <div style={{ height: 'auto', width: '100%' }}></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventCard;
