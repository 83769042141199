import { Spinner } from '@nextui-org/react';
import React from 'react';
const Loading = ({ fullScreen = true }) => {
  return (
    <div
      className={`${fullScreen ? 'min-h-screen' : 'min-h-10'} w-full justify-center flex ${fullScreen ? 'items-center' : 'items-start pt-10'} text-center mx-auto`}
    >
      <Spinner label="Loading..." color="warning" size="lg" />
    </div>
  );
};

export default Loading;

export const GettingData = () => {
  return (
    <div className="min-h-screen w-full justify-center flex items-center text-center mx-auto">
      <Spinner label="Getting data..." color="warning" size="lg" />
    </div>
  );
};
